<template >
  <ion-page>
    <Header type="0" title="零部件点检统计" v-if="menuRole == 'phone'">
    </Header>
    <ion-content scroll-y="true" class="content">
      <div class="allTarget" slot="fixed">
        <div class="categoryBox">
          <div
            class="categoryItem"
            :class="{ cateSelected: dimensionality.isChecked }"
            v-for="(dimensionality, index) in dimensionalityArr"
            :key="index"
            @click="changeCate(index)"
          >
            {{ dimensionality.text }}
          </div>
        </div>
      </div>
      <div class="root">
        <div class="item">
          <div class="left"><span class="required">*</span> 试制任务单号</div>
          <div class="right">
            <ion-input
              v-model.trim="queryParam.stageCode"
              @keyup.enter="openChooseOrganizationModal"
              placeholder="请输入试制任务单号"
            ></ion-input>
          </div>
        </div>
        <div class="item">
          <div class="left"> 零件编号</div>
          <div class="right">
            <ion-input
              v-model.trim="queryParam.partCode"
              @keyup.enter="openChooseOrganizationModal"
              placeholder="请输入零件编号"
            ></ion-input>
          </div>
        </div>
      </div>
      <!-- 无数据  -->
      <div class="no_data" v-if="tableList.length == 0 && !islist">
        <img
          class="no_data_img no_data_top"
          src="@/assets/images/notDate/notSearch.png"
          alt
        />
        <p class="no_data_text">未查询到信息</p>
      </div>
      <part-card
        v-for="item in tableList"
        :key="item.id"
        :dataItem="item"
      ></part-card>
       <div v-show="taskLoading" class="loading">加载中...</div>
      <CardLoading
        v-if="tableList.length <= 0 && islist"
        lodingType="1"
      ></CardLoading>
      <ion-infinite-scroll
        @ionInfinite="taskLoading=true,getList($event)"
        v-if="!noMore"
        threshold="100px"
        id="infinite-scroll"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          loading-text="加载中..."
        >
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-page>
</template>
<script>
import Header from "@/components/Header.vue";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import partCard from "./partCard.vue";
import { partChecksStatistics } from "@/api/partsInfo/index";
import Utils from "@/utils/Utils.ts";
import * as dd from "dingtalk-jsapi";
export default {
  name: "partsInfo",
  components: {
    Header,
    CardLoading,
    partCard,
  },
  data() {
    return {
      islist: false,
      tableList: [],
      dimensionalityArr: [
        { value: "0", text: "未全到", isChecked: true, name: "isUnresolved" },
        { value: "1", text: "未到", isChecked: false, name: "unArrivalNum" },
        { value: "2", text: "全到", isChecked: false, name: "isResolved" },
      ],
      queryParam: {
        stageCode: "",
        partCode:"",
         page: 0,
        pageSize: 20,
      },
      menuRole: "phone",
      noMore:false,
      taskLoading:false
    };
  },
  mounted() {
    // this.getList()

    var _this = this;
    this.menuRole = localStorage.getItem("menuRole");
    if (this.menuRole == "dd") {
      dd.ready(function () {
        dd.biz.navigation.setRight({
          show: false, //控制按钮显示， true 显示， false 隐藏， 默认true
          control: true, //是否控制点击事件，true 控制，false 不控制， 默认false
          text: "扫一扫", //控制显示文本，空字符串表示显示默认文本
          onSuccess: function (result) {
            //如果control为true，则onSuccess将在发生按钮点击事件被回调
            /*
        {}
        */
          },
          onFail: function (err) {},
        });
        dd.biz.navigation.setTitle({
          title: "零部件点检统计", //控制标题文本，空字符串表示显示默认文本
          onSuccess: function (result) {
            /*结构
        {
        }*/
          },
          onFail: function (err) {},
        });
      });
    }
  },
  methods: {
   
    getList(ev) {
       this.islist = true
        this.queryParam.page += 1;
       if (ev) {
          ev.target.complete();
        }
      var list = this.dimensionalityArr.filter((e) => {
        return e.isChecked;
      });
      
      partChecksStatistics({
        stageCode: this.queryParam.stageCode,
        partCode: this.queryParam. partCode,
        status: list[0].text,
        page:this.queryParam.page,
        pageSize:this.queryParam.pageSize
      }).then((res) => {
         this.islist = false
           if (this.queryParam.page == 1) {
            this.tableList = [];
          }
          this.tableList = this.tableList.concat(
            res.data.data.resultData || []
          );
          if (this.tableList.length <= 0 && ev) {
            ev.target.disabled = true;
          }
          this.taskLoading= false
      });
    },
   
    openChooseOrganizationModal() {
     
       if(this.queryParam.stageCode==''){
          Utils.presentToastTopWarning(
                      '请输入试制任务单号',
                      "danger"
                    );
         return false
       }
         this.noMore = false;
       this.queryParam.page=0
       
       this.getList();
    },
    changeCate(index) {
      this.dimensionalityArr.map((e, idx) => {
        if (index == idx) {
          e.isChecked = true;
        } else {
          e.isChecked = false;
        }
      });
       this.tableList = [];
        if(this.queryParam.stageCode==''){
         return false
       }
       
         this.noMore = false;
       this.queryParam.page = 0
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
// ion-item {
//   --padding-end: 12px;
//   --padding-start: 12px;
//   --background: #fff;
// }
// .required {
//   color: #ff0000;
//   margin-right: 6px;
// }
// ion-textarea {
//   --placeholder-color: #333333;
//   --placeholder-opacity: 0.5;
//   --placeholder-font-weight: bold;
//   font-size: 10px;
//   --padding-start: 10px;
//   padding-left: 10px;
// }
// ion-label {
//   font-size: 12px !important;
//   font-family: PingFang SC, PingFang SC-Regular;
//   font-weight: bold;
//   text-align: left;
//   color: #333333;
// }
.icon-lingbujianxinxi {
  font-size: 48px;
}
ion-button {
  --background: #00529c;
}

.star {
  color: red;
  padding-top: px2rem(10);
}
.root:last-child {
  margin-top: px2rem(15);
}
.root {
  width: calc(100% #{px2rem(58)});
  height: auto;
  margin: px2rem(20) auto;
  margin-top: 45px;
  background: #ffffff;
  border-radius: $border-radius;
  box-shadow: 0 0 1rem 0 rgba(21, 40, 102, 0.1);
  padding: px2rem(20) px2rem(45);
  font-size: #{$default-font-size};
}
.lefttwo {
  width: px2rem(270) !important;
  height: auto !important;
  line-height: px2rem(38) !important;
  // text-align: left;
  color: $color !important;
}
.item {
  .title {
    width: 100% !important;
    font-size: px2rem(28) !important;
    font-weight: 700;
    ion-textarea {
      --placeholder-color: #8e8e8e;
      --placeholder-opacity: 1;
    }
  }
}
.uniline {
  border: solid #d6e2ec;
  border-width: 0 0 1px 0;
  width: 100%;
  height: px2rem(88);
  display: flex;
  .left {
    width: 30%;
    line-height: px2rem(88);
    text-align: left;
  }
  .right {
    width: 70%;
    height: px2rem(88);
    text-align: right;
    --color: black;
    line-height: px2rem(88);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .iconfont {
      margin-left: px2rem(25);
      font-size: px2rem(12);
      color: #325cab;
    }
    ion-icon {
      margin-top: px2rem(10);
      width: px2rem(26);
      height: px2rem(26);
      color: #325cab;
    }
  }

  .ser {
    // 放大镜、、暂无
    font-size: px2rem(26);
  }
}
.uniline-last {
  border-width: 0;
}
.multi-line {
  margin-top: 1rem;
  border: solid #d6e2ec;
  border-width: 0 0 1px 0;
  width: 100%;
  border-width: 0;
  text-align: left;
  .textarea {
    width: calc(100%-#{px2rem(24)+3rem});
    --padding-top: 0;
    margin-left: 3rem;
    --color: black;
  }
}
.moreproject {
  text-align: center;
  font-size: px2rem(24px);
  color: #325cab;
  margin-top: px2rem(20px);
}
.categoryBox {
  display: flex;
  justify-content: space-between;
  overflow-x: auto;
  margin: px2rem(20) px2rem(20) px2rem(10);
  .category {
    padding: px2rem(11) px2rem(22);
    font-size: $default-font-size;
    border-radius: 2rem;
    background: #fff;
  }
  .categorySelect {
    background: #f38467;
    color: #fff;
  }
  .categoryItem {
    padding: 0 26px;
    height: 3.375rem;
    background: white;
    line-height: 3.375rem;
    text-align: center;
    color: #6d6c6c;
    font-size: 14px;
  }
  .cateSelected {
    color: #0e67ed;
  }
}
.allTarget {
  width: 100%;
  background: #f5f5f5;
}
.loading {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
}
</style>